<template>
  <ActionGeneric :to="to" label="Visualizza" iconClass="fas fa-eye" :eventName="eventName" :btnClasses="btnClasses" :btnClassesMobile="btnClassesMobile" :disabled="disabled" @click.prevent="$emit('click', $event)" />
</template>

<script>

import actionMixin from './mixins/action.js';

import ActionGeneric from './ActionGeneric.vue';

export default {
  mixins: [actionMixin],
  components: {
    ActionGeneric,
  },
};

</script>
